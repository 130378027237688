import { setConfig } from '../helpers/set_config.helper'
import axios from 'axios'

export async function findeEscapedPatients (admin, token, dateRange, clientId, location) {
  const config = await setConfig(admin, token)
  return await axios.patch(`${process.env.REACT_APP_CONFIRMATION_API}stats/escaped-patients`, {
    dateRange,
    clientId,
    location
  }, config)
}

export async function findUsersNotReturned (admin, token, range, clientId, location) {
  const config = await setConfig(admin, token)
  return await axios.put(`${process.env.REACT_APP_CONFIRMATION_API}stats/not-returned`, {
    range,
    clientId,
    location
  }, config)
}

export async function getConfirmationsByClientAndDate (admin, token, dates, clientId, location) {
  const config = await setConfig(admin, token)
  return await axios.get(`${process.env.REACT_APP_CONFIRMATION_API}stats/${dates}/${clientId}/${location}`, config)
}

export async function getConfirmationsUserByClientAndDate (admin, token, dates, clientId) {
  const config = await setConfig(admin, token)
  return await axios.get(`${process.env.REACT_APP_CONFIRMATION_API}stats/user/${dates}/${clientId}`, config)
}

export async function getConfirmationsUserStatistics (admin, token, dates, clientId, location) {
  const config = await setConfig(admin, token)
  return await axios.get(`${process.env.REACT_APP_CONFIRMATION_API}stats/user-counter/${dates}/${clientId}/${location}`, config)
}

export async function getFirstConfirmationRange (admin, token, clientId, location) {
  const config = await setConfig(admin, token)
  return await axios.get(`${process.env.REACT_APP_CONFIRMATION_API}stats/${clientId}/${location}`, config)
}

export async function getPercentageByClientAndDate (admin, token, data) {
  const config = await setConfig(admin, token)
  return await axios.put(`${process.env.REACT_APP_CONFIRMATION_API}stats/percentage`, data, config)
}
