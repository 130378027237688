import { Modal } from 'bootstrap'
import { Switch } from 'antd'
import parse from 'html-react-parser'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { deleteDoctor, getDoctorsByClient, saveDoctor, updateDoctor, updateNotifyDoctor } from '../../../actions/doctor'
import BaseTable from '../BaseTable/BaseTable'
import { cutText } from '../../../helpers/style_string.helper'
import CreateDrModal from '../../modals/CreateDrModal/CreateDrModal'
import './DoctorsTable.css'

function DoctorsTable () {
  const location = useLocation()
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user)
  const client = useSelector((state) => state.client)
  const token = useSelector((state) => state.token)

  const header = useRef(['Nombre', 'Genero', 'Especialidad', 'Registro', 'Notificar'])
  const [doctors, setDoctors] = useState([])
  const [loading, setLoading] = useState(false)
  const [action, setAction] = useState('create')
  const [newDr, setNewDr] = useState({
    firstName: '',
    lastName: '',
    clientId: client._id,
    gender: 'M',
    specialty: '',
    doctorId: '',
    email: ''
  })

  const [modalState, setModalState] = useState(null)
  const modal = useRef()
  const calledDoctors = useRef(false)

  useEffect(() => {
    setModalState(new Modal(modal.current))
  }, [])

  useEffect(() => {
    let isActive = true
    if (isActive && !calledDoctors.current) {
      calledDoctors.current = true
      getDoctors()
    }
    return function cleanup () {
      isActive = false
    }
  }, [])

  function updateLocalStorage (data, objToUpdate, type) {
    window.localStorage.setItem(objToUpdate, JSON.stringify(data))
    dispatch({
      type,
      payload: data
    })
  }

  async function getDoctors () {
    try {
      const res = await getDoctorsByClient(user, client._id, token)
      if (res && res?.data && res.data.length > 0) {
        setDoctors(res.data)
        updateLocalStorage(res.data, 'doctors', 'NEW_DOCTORS')
      }
    } catch (error) {
      toast.error(error.response.data.error || error.response.data.message)
    }
  }

  async function createDr (newDoctor, setNewDoctor) {
    setLoading(true)
    try {
      const res = await saveDoctor(user, token, newDoctor)
      modalState.hide()
      if (res?.data) {
        setDoctors(prevData => [...prevData, res.data])
        await getDoctors()
        toast.success('Doctor agregado con éxito.')
      }
    } catch (error) {
      toast.error(error.response.data.error || error.response.data.message)
    }
    setLoading(false)
  }

  function update (row) {
    setAction('update')
    const foundDoctor = doctors.find((d) => d.doctorId === row.doctorId)
    const { firstName, lastName, specialty, doctorId, gender, email } = foundDoctor
    setNewDr(prevData => ({
      ...prevData,
      firstName,
      lastName,
      gender,
      specialty,
      doctorId,
      email
    }))
    modalState.show()
  }

  async function updateDr (updatedDr, setNewDoctor) {
    setLoading(true)
    try {
      const foundDoctor = doctors.find((d) => d.doctorId === updatedDr.doctorId)
      const res = await updateDoctor(user, token, { ...updatedDr, _id: foundDoctor._id })
      modalState.hide()
      if (res?.data) {
        setDoctors(prevData => {
          return prevData.map((d) => {
            if (d._id === res.data._id) {
              return res.data
            }
            return d
          })
        })
        await getDoctors()
        toast.success('Doctor actualizado con éxito.')
      }
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.error || error.response.data.message)
    }
    setLoading(false)
  }

  async function updateNotify (id, notify) {
    setLoading(true)
    try {
      const foundDoctor = doctors.find((d) => d.doctorId === id)
      const res = await updateNotifyDoctor(user, token, foundDoctor._id, notify)
      if (res.data) {
        setDoctors(prevData => {
          return prevData.map((d) => {
            if (d._id === res.data._id) {
              return res.data
            }
            return d
          })
        })
        await getDoctors()
      }
    } catch (error) {
      toast.error(error.response.data.error || error.response.data.message)
    }
    setLoading(false)
  }

  async function deleteDr (row) {
    if (
      !window.confirm(
        '¿Estas seguro que quieres eliminar este doctor? Esta acción no se puede deshacer.'
      )
    ) { return }

    const foundDoctor = doctors.find((d) => d.doctorId === row.doctorId)
    if (foundDoctor !== '') {
      try {
        const res = await deleteDoctor(user, token, foundDoctor._id)
        if (res?.data) {
          const newDoctorsArray = doctors.filter((d) => d._id !== res.data._id)
          setDoctors(newDoctorsArray)
          toast.success('Doctor eliminado con éxito.')
        }
      } catch (error) {
        toast.error(error.response.data.error || error.response.data.message)
      }
    }
  }

  function formatDoctorsJson (resDoctors) {
    const formattedDoctors = []
    // Only admins can edit doctors
    if (user.role === 'ADMIN' && !header.current.includes('Opciones')) {
      header.current.push('Opciones')
    }
    resDoctors.forEach((d) => {
      const formattedDr = {
        name: `${d.firstName} ${d.lastName}`,
        gender: d.gender,
        specialty: d.specialty,
        doctorId: d.doctorId
      }

      if (user.role === 'ADMIN') {
        formattedDr.notify = `<button id="switch" value="${d.notify}" ></button>`
        formattedDr.options = '<button id="options"></button>'
      }

      formattedDoctors.push(formattedDr)
    })
    return formattedDoctors
  }

  function formatRowData (rowData, row, i) {
    if (user.role === 'ADMIN' &&
    location.pathname === '/profile' &&
      rowData !== null &&
      rowData !== undefined
    ) {
      if (rowData.includes('<button')) {
        return parse(rowData, {
          replace: ({ attribs }) => {
            if (attribs && attribs.id === 'options') {
              return (
                <div className='doctors-table-options'>
                  <button
                    data-target='#staticBackdrop'
                    onClick={() => update(row)}
                    className='btn main-btn dr-btn edit-btn text-nowrap'
                  >
                    <i className='fas fa-pen' />
                  </button>
                  <button
                    onClick={async () => await deleteDr(row)}
                    className='btn main-btn dr-btn delete-btn text-nowrap'
                  >
                    <i className='fas fa-trash-alt' />
                  </button>
                </div>
              )
            } else if (attribs && attribs.id === 'switch') {
              const value = attribs.value === 'true'
              return (
                <Switch
                  style={value ? { backgroundColor: '#314045' } : { backgroundColor: '#6d8b96' }}
                  loading={loading}
                  checkedChildren='SI'
                  unCheckedChildren='NO'
                  checked={value}
                  onChange={async (isChecked) => await updateNotify(row.doctorId, isChecked)}
                />
              )
            }
          }
        })
      }
      if (rowData.length > 30) {
        return cutText(rowData, true, 30)
      }
    }

    return rowData
  }

  return (
    <div className='users'>
      {newDr &&
        <CreateDrModal
          modal={modal}
          createDr={createDr}
          updateDr={updateDr}
          modalState={modalState}
          loading={loading}
          action={action}
          newDr={newDr}
          setNewDr={setNewDr}
        />}
      {window.outerWidth > 1028 && <h1 className='information-title'>Doctores</h1>}
      <div className='users-container'>
        {user.role === 'ADMIN'
          ? (
            <div className='row d-flex'>
              <div className='col-md-9 title-col-1'>
                <p>
                  Aqui puedes ver a los doctores creados para esta cuenta.
                  Eres el propietario y solo tu puedes agregar, editar y eliminar a los doctores que se encuentran debajo.
                </p>
              </div>
              <div className='col-md-3 title-col-2'>
                <button
                  data-target='#staticBackdrop'
                  className='btn main-btn' onClick={() => {
                    setNewDr(prevData => ({ ...prevData, firstName: '', lastName: '', gender: 'M', specialty: '', doctorId: '', email: '' }))
                    setAction('create')
                    modalState.show()
                  }}
                >Agregar doctor
                </button>
              </div>
            </div>
            )
          : (
            <div className='row d-flex'>
              <div className='col-md-12 title-col-1'>
                <p>
                  Aqui puedes ver a los doctores creados para esta cuenta.
                  Solo el propietario de la cuenta puede agregar, editar y eliminar a los doctores que se encuentran debajo.
                </p>
              </div>
            </div>
            )}

        <BaseTable
          json={formatDoctorsJson(doctors)}
          header={header.current}
          formatRowData={formatRowData}
        />
      </div>
    </div>
  )
}

export default DoctorsTable
