import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { CircleSpinner, MetroSpinner } from 'react-spinners-kit'
import { toast } from 'react-toastify'

import UsageCard from '../../cards/UsageCard/UsageCard'
import { getDateRange, numberWithCommas, subtractOneMonth } from '../../../helpers/style_string.helper'
import { flowStates } from '../../../constants/flows'
import BaseTable from '../../tables/BaseTable/BaseTable'
import {
  findeEscapedPatients,
  findUsersNotReturned,
  getConfirmationsByClientAndDate,
  getConfirmationsUserByClientAndDate,
  getConfirmationsUserStatistics,
  getFirstConfirmationRange,
  getPercentageByClientAndDate
} from '../../../actions/stats'

import { sumPercentages, sumStates } from '../../../helpers/table.helper'
import { getBotConversationCountByClientAndDate } from '../../../actions/bot'
import RangeDatePicker from '../../pickers/RangeDatePicker/RangeDatePicker'
import clients from '../../../constants/clients'
import { clientLocation } from '../../../constants/location'
import './UsageInfo.css'

function UsageInfo () {
  const client = useSelector((state) => state.client)
  const user = useSelector((state) => state.user)
  const token = useSelector((state) => state.token)
  const locations = clientLocation[client._id]
  const [hasCall, setHasCall] = useState(false)
  const [dates, setDates] = useState('')
  const [dateRange, setDateRange] = useState([null, null])
  const [usersNotReturned, setUsersNotReturned] = useState({ header: [], json: [] })
  const [isLoaderNotReturned, setIsLoaderNotReturned] = useState(true)
  const [notReturnedRange, setNotReturnedRange] = useState('1-year')
  const [arrNotReturnedRange, setArrNotReturnedRange] = useState([])
  const [collaboratorManagement, setCollaboratorManagement] = useState({ header: [], json: [] })
  const [isLoadStatistics, setIsLoadStatistics] = useState(true)
  const [isLoaderEscapedPatients, setIsLoaderEscapedPatients] = useState(true)
  const [selectedLocation, setSelectedLocation] = useState('Todas')
  const [escapedPatients, setEscapedPatients] = useState({ counter: 0, percentage: 0 })
  const [statistics, setStatistics] = useState({
    currentDate: {
      total: 0,
      counter: 0
    },
    previousDate: {
      total: 0,
      counter: 0
    },
    percentage: 0
  })
  const [isLoadingStates, setIsLoadingStates] = useState(true)
  const [isLoadingPercentage, setIsLoadingPercentage] = useState(true)
  const initialStates = {
    sentWP: 0,
    confirmedWP: 0,
    cancelledWP: 0,
    callWP: 0,
    otherWP: 0,
    noReplyWP: 0,
    botWP: 0,
    informative: 0,
    sentCall: 0,
    confirmedCall: 0,
    cancelledCall: 0,
    callCall: 0,
    otherCall: 0,
    noReplyCall: 0,
    notAttendedWP: 0,
    rescheduledWP: 0,
    noAnswerWP: 0,
    paymentWP: 0,
    cancellationsCall: 0,
    confirmationsCall: 0,
    patientWillCallWP: 0
  }
  const [states, setStates] = useState(initialStates)
  const [percentageStates, setPercentageStates] = useState(initialStates)
  const [total, setTotal] = useState({
    messages: 0,
    bot: 0,
    calls: 0,
    total: 0
  })
  const [isLoading, setIsLoading] = useState(true)
  const [isThisMonth, setIsThisMonth] = useState(true)
  const loadingData = useRef(false)

  let valuePerMessage = 136.875
  const valuePerCall = 240.9

  useEffect(() => {
    let isActive = true
    if (isActive && !loadingData.current) {
      loadingData.current = true
      setIsLoadingStates(true)
      setIsLoadingPercentage(true)
      setIsLoaderEscapedPatients(true)
      setIsLoaderNotReturned(true)
      setIsLoadStatistics(true)
      setIsLoading(true)

      hasCallInFlow()
      getConfirmations()
      getCollaboratorManagement()
      getConfirmationsUserStats()
      findUsersNotReturnedDB()
      getEscapedPatients()
      setIsLoading(false)
    }

    return () => {
      isActive = false
    }
  }, [dates, selectedLocation])

  function hasCallInFlow () {
    const clientFlows = flowStates[client._id] ? flowStates[client._id] : flowStates.Default
    const clientStates = Object.values(clientFlows)
      .map(f => Object.keys(f))
      .flat()
      .filter((item, pos, self) => self.indexOf(item) === pos)
    clientStates.forEach(s => {
      const state = s.split('_')[0]
      if (state.includes('CALL')) setHasCall(true)
    })
  }

  function formatManagementCollaborator (data) {
    const header = ['Nombre', 'Gestiones']
    const json = data.map((d) => {
      return {
        Nombre: d.firstName + ' ' + d.lastName,
        Gestiones: d.count
      }
    })
    return { header, json }
  }

  function formatRowData (rowData, row, i) {
    return rowData
  }

  async function getEscapedPatients () {
    setIsLoaderEscapedPatients(true)
    const currentDate = dates || getDateRange()
    const { data: currentData } = await findeEscapedPatients(user, token, currentDate, client._id, selectedLocation)
    const { formattedStartDate, formattedEndDate } = subtractOneMonth(currentDate)
    const previousDate = `${formattedStartDate}_${formattedEndDate}`
    const { data: previousData } = await findeEscapedPatients(user, token, previousDate, client._id, selectedLocation)
    if (currentData && previousData) {
      const percentage = ((currentData.counter - previousData.counter) / previousData.counter * 100).toFixed(2)
      setEscapedPatients({ counter: currentData.counter, percentage: parseFloat(percentage) })
    }
    setIsLoaderEscapedPatients(false)
  }

  function callNotReturned (range) {
    setNotReturnedRange(range)
    findUsersNotReturnedDB()
  }

  async function findUsersNotReturnedDB () {
    setIsLoaderNotReturned(true)
    let queryRange
    if (arrNotReturnedRange.length === 0) {
      const { data: range } = await getFirstConfirmationRange(user, token, client._id, selectedLocation)
      queryRange = range[0] === '1-year' ? '1-year' : range[0] === '6-months' ? '6-months' : '3-months'
      setArrNotReturnedRange(range)
      setNotReturnedRange(queryRange)
    } else {
      queryRange = notReturnedRange
    }
    const { data } = await findUsersNotReturned(user, token, queryRange, client._id, selectedLocation)
    if (data) {
      const header = ['Nombre', 'Teléfono', 'Ultima Cita', 'Procedimiento']
      const json = data.map((d) => {
        const date = new Date(d.lastConfirmation).toLocaleDateString('es-ES', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        })
        return {
          name: d._id.name,
          number: d._id.number,
          lastDate: date,
          procedure: d.procedure
        }
      })
      setUsersNotReturned({ header, json })
    }
    setIsLoaderNotReturned(false)
  }

  async function getCollaboratorManagement () {
    const d = dates || getDateRange()
    const { data: usersData } = await getConfirmationsUserByClientAndDate(user, token, d, client._id)
    setCollaboratorManagement(formatManagementCollaborator(usersData))
  }

  async function getConfirmationsUserStats () {
    setIsLoadStatistics(true)
    const currentDate = dates || getDateRange()
    const { formattedStartDate, formattedEndDate } = subtractOneMonth(currentDate)
    const previousDate = `${formattedStartDate}_${formattedEndDate}`
    const { data: currentRange } = await getConfirmationsUserStatistics(user, token, currentDate, client._id, selectedLocation)
    const { data: previousRange } = await getConfirmationsUserStatistics(user, token, previousDate, client._id, selectedLocation)
    const percentage = ((currentRange.counter - previousRange.counter) / previousRange.counter * 100).toFixed(2)
    setStatistics({
      currentDate: currentRange,
      previousDate: previousRange,
      percentage
    })
    setIsLoadStatistics(false)
  }

  async function getConfirmations () {
    const d = dates || getDateRange()
    try {
      const { data } = await getConfirmationsByClientAndDate(user, token, d, client._id, selectedLocation)
      const { data: botData } = await getBotConversationCountByClientAndDate(user, token, d, client._id)
      if (data) {
        const counterStates = sumStates(data, botData, client._id)
        setStates(counterStates)
        setIsLoadingStates(false)
        await getPercentage(d, data)

        let priceMessages = counterStates.sentWP * valuePerMessage
        if (client._id === clients.DERMATOLOGICA) {
          // For Dermatologica we charge by range of messages
          const minimumWage = parseInt(process.env.REACT_APP_MINIMUM_WAGE)
          let multiplier = 0.94
          const totalSent = counterStates.sentWP + counterStates.botWP + counterStates.informative
          if (totalSent > 3500 && totalSent <= 5000) {
            multiplier = 1.27
          } else if (totalSent > 5000 && totalSent <= 7500) {
            multiplier = 1.79
          } else if (totalSent > 7500) {
            multiplier = 2.23
          }
          priceMessages = minimumWage * multiplier
        } else if (!(client._id === clients.PAULINA_ZARRABE ||
            client._id === clients.CASA_DE_SONRISAS ||
            client._id === clients.KI_PLANNER) &&
          counterStates.sentWP + botData.total > 500) {
          // We only charge after 500 messages and valuePerMessage changes
          valuePerMessage = 273.75
          priceMessages = (counterStates.sentWP - 500) * valuePerMessage + 547500
        }
        const priceBot = client._id === clients.DERMATOLOGICA ? 0 : botData.total * valuePerMessage
        const priceCalls = counterStates.sentCall * valuePerCall
        const t = hasCall
          ? priceMessages + priceCalls + priceBot
          : priceMessages + priceBot

        if (client._id === clients.DERMATOLOGICA) {
          setTotal({
            messages: numberWithCommas(priceMessages),
            bot: 0,
            calls: numberWithCommas(priceCalls),
            total: numberWithCommas(t)
          })
        } else {
          setTotal({
            messages: numberWithCommas(priceMessages),
            bot: numberWithCommas(priceBot),
            calls: numberWithCommas(priceCalls),
            total: numberWithCommas(t)
          })
        }
      }
    } catch (e) {
      setIsLoadingStates(false)
      toast.error('Error de carga.')
    }
  }

  async function getPercentage (d, currentDateCounter) {
    try {
      const { data } = await getPercentageByClientAndDate(user, token, {
        dates: d,
        clientId: client._id,
        location: selectedLocation,
        currentDateCounter
      })
      if (data) {
        const percentages = sumPercentages(client._id, data)
        setPercentageStates(percentages)
        setIsLoadingPercentage(false)
      }
    } catch (e) {
      setIsLoadingPercentage(false)
      toast.error('Error de carga.')
    }
  }

  function handleClick () {
    setIsThisMonth(false)
  }

  return (
    <div className='usage'>
      {window.outerWidth > 1028 && (
        <div className='row usage-title'>
          <div className='col-md-7'>
            <h1 className='information-title'>Uso de la plataforma</h1>
          </div>
          <div className='col-md-3 filter-btn'>
            {
              locations.length > 1 && (
                <div className='d-flex align-items-center'>
                  <label className='me-2' htmlFor='location'>Sede</label>
                  <select
                    className='form-select form-control'
                    value={selectedLocation}
                    onChange={(e) => {
                      loadingData.current = false
                      setSelectedLocation(e.target.value)
                    }}
                  >
                    {locations.map((location, i) => {
                      return (
                        <option key={i} value={location}>{location}</option>
                      )
                    })}
                  </select>
                </div>
              )
            }
          </div>
          <div className='col-md-2 filter-btn'>
            {isThisMonth
              ? (
                <button
                  className='btn main-btn'
                  onClick={handleClick}
                  disabled={isLoading || Object.keys(states).length === 0}
                >
                  Este mes
                </button>
                )
              : (
                <RangeDatePicker
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  setDates={setDates}
                  loadingData={loadingData}
                />
                )}
          </div>
        </div>
      )}
      <div className='usage-container'>
        <p className='usage-description'>En esta sección podrás ver el uso que le das a plataforma.</p>
        {isLoading
          ? (
            <div className='loading-container'>
              <div className='loading'>
                <div className='loading-animation'>
                  <MetroSpinner size={70} color='#304044' loading={isLoading} />
                </div>
              </div>
            </div>
            )
          : Object.keys(states).length > 0
            ? (
              <div className='row total-row'>
                <div className='col-md-9'>
                  <div className='row usage-container-row'>
                    <h5 className='usage-section-title'><i className='fas fa-comments' /> Mensajes</h5>
                    <UsageCard
                      title='Total' value={states.sentWP}
                      icon='fa-comments'
                      color='total'
                      isLoadingStates={isLoadingStates}
                      isLoadingPercentage={isLoadingPercentage}
                      percentage={percentageStates.sentWP}
                      isIncreasing={percentageStates.sentWP > 0}
                    />
                    <UsageCard
                      title='Confirmados' value={states.confirmedWP} total={states.sentWP} icon='fa-check'
                      color='confirm'
                      isLoadingStates={isLoadingStates}
                      isLoadingPercentage={isLoadingPercentage}
                      percentage={percentageStates.confirmedWP}
                      isIncreasing={percentageStates.confirmedWP > 0}
                    />
                    <UsageCard
                      title='Cancelados' value={states.cancelledWP} total={states.sentWP} icon='fa-times'
                      color='cancel'
                      isLoadingStates={isLoadingStates}
                      isLoadingPercentage={isLoadingPercentage}
                      percentage={percentageStates.cancelledWP}
                      isIncreasing={percentageStates.cancelledWP > 0}
                    />
                    <UsageCard
                      title='Contactar' value={states.callWP} total={states.sentWP} icon='fa-phone-alt'
                      color='call'
                      isLoadingStates={isLoadingStates}
                      isLoadingPercentage={isLoadingPercentage}
                      percentage={percentageStates.callWP}
                      isIncreasing={percentageStates.callWP > 0}
                    />
                    <UsageCard
                      title='Escritura' value={states.otherWP} total={states.sentWP} icon='fa-pencil-alt'
                      color='other'
                      isLoadingStates={isLoadingStates}
                      isLoadingPercentage={isLoadingPercentage}
                      percentage={percentageStates.otherWP}
                      isIncreasing={percentageStates.otherWP > 0}
                    />
                    <UsageCard
                      title='Ignorado' value={states.noReplyWP} total={states.sentWP} icon='fa-user-alt-slash'
                      color='ignored'
                      isLoadingStates={isLoadingStates}
                      isLoadingPercentage={isLoadingPercentage}
                      percentage={percentageStates.noReplyWP}
                      isIncreasing={percentageStates.noReplyWP > 0}
                    />
                    {clients.WAKEUP === client._id &&
                      <UsageCard
                        title='Pago' value={states.paymentWP} total={states.sentWP} icon='fa-dollar-sign'
                        color='payment'
                        isLoadingStates={isLoadingStates}
                        isLoadingPercentage={isLoadingPercentage}
                        percentage={percentageStates.paymentWP}
                        isIncreasing={percentageStates.paymentWP > 0}
                      />}
                    <UsageCard
                      title='Reagendado' value={states.rescheduledWP} total={states.sentWP}
                      icon='fa-calendar-check' color='rescheduled'
                      isLoadingStates={isLoadingStates}
                      isLoadingPercentage={isLoadingPercentage}
                      percentage={percentageStates.rescheduledWP}
                      isIncreasing={percentageStates.rescheduledWP > 0}
                    />
                    <UsageCard
                      title='Paciente llamará' value={states.patientWillCallWP} total={states.sentWP}
                      icon='fa-phone-alt' color='patient-will-call'
                      isLoadingStates={isLoadingStates}
                      isLoadingPercentage={isLoadingPercentage}
                      percentage={percentageStates.patientWillCallWP}
                      isIncreasing={percentageStates.patientWillCallWP > 0}
                    />
                    <UsageCard
                      title='No contestaron' value={states.noAnswerWP} total={states.sentWP}
                      icon='fa-comment-slash' color='no-answer'
                      isLoadingStates={isLoadingStates}
                      isLoadingPercentage={isLoadingPercentage}
                      percentage={percentageStates.noAnswerWP}
                      isIncreasing={percentageStates.noAnswerWP > 0}
                    />
                    <UsageCard
                      title='No asistió' value={states.notAttendedWP} total={states.sentWP}
                      icon='fa-calendar-times' color='not-attended'
                      isLoadingStates={isLoadingStates}
                      isLoadingPercentage={isLoadingPercentage}
                      percentage={percentageStates.notAttendedWP}
                      isIncreasing={percentageStates.notAttendedWP > 0}
                    />
                    <hr />
                    <h5 className='usage-section-title'><i className='fas fa-robot' /> Respuestas automáticas</h5>
                    <UsageCard
                      title='Bot' value={states.botWP} icon='fa-robot' color='bot'
                      isLoadingStates={isLoadingStates}
                    />
                    <UsageCard
                      title='Encuestas' value={states.informative} icon='fa-info' color='bot'
                      isLoadingStates={isLoadingStates}
                      isLoadingPercentage={isLoadingPercentage}
                      percentage={percentageStates.informative}
                      isIncreasing={percentageStates.informative > 0}
                    />
                    <hr />
                    <h5 className='usage-section-title'><i className='fas fa-user' />Estadísticas Pacientes</h5>
                    <UsageCard
                      title='Nuevos'
                      icon='fa-user-plus'
                      value={statistics.currentDate.total}
                      percentage={statistics.percentage}
                      isIncreasing={statistics.percentage > 0}
                      isLoadingStates={isLoadStatistics}
                      isLoadingPercentage={isLoadingPercentage}
                    />
                    <UsageCard
                      title='Fugados'
                      icon='fa-user-minus'
                      value={escapedPatients.counter}
                      percentage={escapedPatients.percentage}
                      isIncreasing={escapedPatients.percentage > 0}
                      isLoadingStates={isLoaderEscapedPatients}
                      isLoadingPercentage={isLoaderEscapedPatients}
                    />
                    {usersNotReturned.json.length > 0 && (
                      <div>
                        <div className='button-bar'>
                          <h6>Pacientes no reagendados</h6>
                          {
                            isLoaderNotReturned && (
                              <div className='loaderDiv'>
                                <CircleSpinner size={20} color='#304044' className='loader' />
                              </div>
                            )
                          }
                          {
                            arrNotReturnedRange.map((range, i) => {
                              const name = range === '1-year' ? '1 año' : range === '6-months' ? '6 meses' : '3 meses'
                              return (
                                <button
                                  key={i}
                                  className={`${isLoaderNotReturned ? 'button-bar-disabled' : notReturnedRange === range ? 'active' : ''}`}
                                  onClick={() => !isLoaderNotReturned && callNotReturned(range)}
                                  disabled={isLoaderNotReturned}
                                >
                                  {name}
                                </button>
                              )
                            })
                          }
                          <div className='result-count-container'>
                            <i className='fas fa-user' />
                            <span className='result-count'>
                              {usersNotReturned.json.length}
                            </span>
                          </div>
                        </div>
                        <div className='user-management'>
                          <BaseTable
                            json={usersNotReturned.json}
                            header={usersNotReturned.header}
                            formatRowData={formatRowData}
                          />
                        </div>

                      </div>
                    )}
                    {hasCall && (
                      <>
                        <hr />
                        <h5 className='usage-section-title'><i className='fas fa-headset' />Llamadas automáticas</h5>
                        <UsageCard
                          title='Total' value={states.sentCall} icon='fa-headset' color='total'
                          isLoadingStates={isLoadingStates}
                          isLoadingPercentage={isLoadingPercentage}
                          percentage={percentageStates.sentCall}
                          isIncreasing={percentageStates.sentCall > 0}
                        />
                        <UsageCard
                          title='Confirmados' value={states.confirmationsCall} total={states.sentCall}
                          icon='fa-check' color='confirm'
                          isLoadingStates={isLoadingStates}
                          isLoadingPercentage={isLoadingPercentage}
                          percentage={percentageStates.confirmationsCall}
                        />
                        <UsageCard
                          title='Cancelados' value={states.cancellationsCall} total={states.sentCall}
                          icon='fa-times' color='cancel'
                          isLoadingStates={isLoadingStates}
                          isLoadingPercentage={isLoadingPercentage}
                          percentage={percentageStates.cancellationsCall}
                          isIncreasing={percentageStates.cancellationsCall > 0}
                        />
                        <UsageCard
                          title='Contactar' value={states.callCall} total={states.sentCall} icon='fa-phone-alt'
                          color='call'
                          isLoadingStates={isLoadingStates}
                          isLoadingPercentage={isLoadingPercentage}
                          percentage={percentageStates.callCall}
                          isIncreasing={percentageStates.callCall > 0}
                        />
                        <UsageCard
                          title='Ignorado' value={states.noReplyCall} total={states.sentCall}
                          icon='fa-user-alt-slash' color='ignored'
                          isLoadingStates={isLoadingStates}
                          isLoadingPercentage={isLoadingPercentage}
                          percentage={percentageStates.noReplyCall}
                          isIncreasing={percentageStates.noReplyCall > 0}
                        />
                      </>
                    )}
                    {collaboratorManagement.json.length > 0 && (
                      <>
                        <hr />
                        <h5 className='usage-section-title'><i className='fas fa-users' />Gestiones por usuario</h5>
                        <div className='user-management'>
                          <BaseTable
                            json={collaboratorManagement.json}
                            header={collaboratorManagement.header}
                            formatRowData={formatRowData}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {client._id === clients.DERMATOLOGICA
                  ? (
                    <div className='col-md-3 order-first order-md-last'>
                      <div className='row totals usage-container-row'>
                        <h5 className='usage-section-title'>Resumen</h5>
                        <div className='col-6 total-txt'><p>Mensajes</p></div>
                        <div className='col-6 total-value text-nowrap'>{'$ ' + total.messages}</div>
                        {hasCall && (
                          <>
                            <div className='col-6 total-txt'><p>Llamadas</p></div>
                            <div className='col-6 total-value text-nowrap'>{'$ ' + total.calls}</div>
                          </>
                        )}
                        <hr />
                        <div className='col-6 total-txt total'><p>Total</p></div>
                        <div className='col-6 total-value total text-nowrap'>{'$ ' + total.total}</div>
                      </div>
                    </div>
                    )
                  : (
                      (client._id === clients.PAULINA_ZARRABE ||
                    client._id === clients.CASA_DE_SONRISAS ||
                    client._id === clients.KI_PLANNER) ||
                  states.sentWP + states.botWP > 500) && (
                    <div className='col-md-3 order-first order-md-last'>
                      <div className='row totals usage-container-row'>
                        <h5 className='usage-section-title'>Resumen</h5>
                        <div className='col-6 total-txt'><p>Mensajes</p></div>
                        <div className='col-6 total-value text-nowrap'>{'$ ' + total.messages}</div>
                        <div className='col-6 total-txt'><p>Bot</p></div>
                        <div className='col-6 total-value text-nowrap'>{'$ ' + total.bot}</div>
                        {hasCall && (
                          <>
                            <div className='col-6 total-txt'><p>Llamadas</p></div>
                            <div className='col-6 total-value text-nowrap'>{'$ ' + total.calls}</div>
                          </>
                        )}
                        <hr />
                        <div className='col-6 total-txt total'><p>Total</p></div>
                        <div className='col-6 total-value total text-nowrap'>{'$ ' + total.total}</div>
                      </div>
                    </div>
                    )}
              </div>
              )
            : (
              <div>
                <p className='no-info'>No se encontró información</p>
              </div>
              )}
      </div>
    </div>
  )
}

export default UsageInfo
