import axios from 'axios'
import { setConfig } from '../helpers/set_config.helper'

export async function saveEmail (email) {
  return await axios.post(`${process.env.REACT_APP_CONFIRMATION_API}user/register`, { email })
}

export async function register (user, client) {
  return await axios.put(`${process.env.REACT_APP_CONFIRMATION_API}user/register`, { user, client })
}

export async function login (user) {
  return await axios.post(`${process.env.REACT_APP_CONFIRMATION_API}user/login`, user)
}

export async function recoverPassword (email) {
  return await axios.post(`${process.env.REACT_APP_CONFIRMATION_API}user/recover-password`, { email })
}

export async function verifyToken (user, token) {
  return await axios.post(process.env.REACT_APP_CONFIRMATION_API + 'user/verify-token', { user, token })
}

export async function getUsersByClient (user, clientId, token) {
  const config = await setConfig(user, token)
  return await axios.get(`${process.env.REACT_APP_CONFIRMATION_API}user/${clientId}`, config)
}

export async function createNewUser (user, adminUser, token) {
  const config = await setConfig(adminUser, token)
  return await axios.post(process.env.REACT_APP_CONFIRMATION_API + 'user/create', {
    user,
    adminUserEmail: adminUser.email
  }, config)
}

export async function updateUserRole (user, admin, token) {
  const config = await setConfig(admin, token)
  return await axios.put(process.env.REACT_APP_CONFIRMATION_API + 'user/update-role', { user }, config)
}

export async function deleteUser (user, admin, token) {
  const config = await setConfig(admin, token)
  return await axios.post(process.env.REACT_APP_CONFIRMATION_API + 'user/delete', { userId: user._id }, config)
}

export async function updateUserAndClient (user, client, token) {
  const config = await setConfig(user, token)
  return await axios.post(process.env.REACT_APP_CONFIRMATION_API + 'user/update', { user, client }, config)
}

export async function updateUser (user, admin, token) {
  const config = await setConfig(admin, token)
  return await axios.put(process.env.REACT_APP_CONFIRMATION_API + 'user/update', user, config)
}

export async function updatePassword (user, passwordData, token) {
  const config = await setConfig(user, token)
  return await axios.put(process.env.REACT_APP_CONFIRMATION_API + 'user/password', passwordData, config)
}

export async function hasPassword (userId) {
  return await axios.get(`${process.env.REACT_APP_CONFIRMATION_API}user/has-password/${userId}`)
}

export async function saveNewPassword (user) {
  return await axios.put(process.env.REACT_APP_CONFIRMATION_API + 'user/new-password', user)
}
