import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import * as Realm from 'realm-web'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import { useTitle } from '../../helpers/use_title.helper'
import { countResponseStatus, formatContact, formatGuardian, formatRowStatus } from '../../helpers/table.helper'
import { formatDate, formatHeader, strToDate, strToTime } from '../../helpers/style_string.helper'
import { updateConfirmationStatus } from '../../actions/confirmation'
import { reorderJsonTable } from '../../helpers/excel_json.helper'
import Menu from '../../components/menus/Menu/Menu'
import Footer from '../../components/Footer/Footer'
import CampaignTable from '../../components/tables/CampaignTable/CampaignTable'
import { MetroSpinner } from 'react-spinners-kit'
import './Main.css'
import clients from '../../constants/clients'

const app = new Realm.App({ id: 'application-0-ubmus' })

function Main () {
  useTitle('Ki | Confirmación')
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const client = useSelector((state) => state.client)
  const token = useSelector((state) => state.token)

  const [fileName, setFileName] = useState('')
  const [json, setJson] = useState([])
  const [header, setHeader] = useState([])
  const [loadingTable, setLoadingTable] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [dateForDB, setDateForDB] = useState(formatDate(new Date()))
  const [campaignState, setCampaignState] = useState('')
  const [campaignState2, setCampaignState2] = useState('')
  const [tableState, setTableState] = useState({})
  const [lastChange, setLastChange] = useState({})
  const [flowStates, setFlowStates] = useState({
    sent: 0,
    delivered: 0,
    read: 0,
    answered: 0,
    confirmed: 0,
    cancelled: 0,
    call: 0,
    other: 0,
    noReply: 0,
    notAttended: 0,
    rescheduled: 0,
    patientWillCall: 0
  })
  const [filter, setFilter] = useState({
    baseTable: 'all'
  })
  const [savingMessagesStatus, setSavingMessagesStatus] = useState('')
  const [states, setStates] = useState([])
  const events = useRef([])
  const isHistory = useRef(false)
  useEffect(() => {
    let isActive = true

    try {
      if (isActive) {
        const login = async () => {
          // Authenticate anonymously
          await app.logIn(Realm.Credentials.anonymous())
          // Connect to the database
          const mongodb = app.currentUser.mongoClient(process.env.REACT_APP_MONGO_CLIENT)
          const collection = mongodb.db(process.env.REACT_APP_MONGO_DB).collection(process.env.REACT_APP_MONGO_COLLECTION)
          // Everytime a change happens in the stream, add it to the list of events
          for await (const change of collection.watch()) {
            const { operationType, fullDocument: updatedConfirmation } = change
            if (operationType === 'update') {
              updatedConfirmation._id = updatedConfirmation._id.toString()
              if (events.current.length === 0 || (_.find(events.current, change) === undefined)) {
                setLastChange(updatedConfirmation)
                events.current = [...events.current, change]
              }
            }
          }
        }
        if (tableState && Object.keys(tableState).length > 0) {
          login().then(r => r)
        }
      }
    } catch (error) {
      console.log(error)
    }

    return function cleanup () {
      isActive = false
    }
  }, [tableState])

  useEffect(() => {
    let isActive = true

    if (isActive) {
      if (Object.keys(tableState).includes('_id') &&
        lastChange &&
        Object.keys(lastChange).length > 0
        // &&
        // lastChange?.tableId?.toString() === tableState?._id
      ) {
        setTableState(prevTable => {
          const confirmations = prevTable.confirmations
          confirmations.forEach(c => {
            if (c._id === lastChange._id) {
              c.status = lastChange.status
              c.wpStatus = lastChange.wpStatus
            }
          })
          // Reorder json array
          updateLocalStorage(prevTable, 'table', 'NEW_TABLE')
          const campaign = campaignState === '' ? campaignState2 : campaignState
          formatJson(confirmations, campaign, true, campaignState2 === '6 month', false)
          return prevTable
        })
      }
    }

    return function cleanup () {
      isActive = false
    }
  }, [lastChange])

  async function onWpStateChange (e, confirmationId) {
    try {
      const value = e.target.value + '-' + user._id
      const payload = { status: value, confirmationId }
      await updateConfirmationStatus(user, token, payload)
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) toast.error(error.response.data.error || error.response.data.message)
      }
    }
  }

  function reset () {
    setFlowStates({
      sent: 0,
      delivered: 0,
      read: 0,
      answered: 0,
      confirmed: 0,
      cancelled: 0,
      call: 0,
      other: 0,
      noReply: 0,
      notAttended: 0,
      rescheduled: 0,
      patientWillCall: 0
    })
    setJson([])
    setFileName('')
    setHeader([])
    setSavingMessagesStatus('')
    updateLocalStorage(null, 'table', 'CLEAR_TABLE')
    resetFilters()
    isHistory.current = false
  }

  function resetFilters () {
    setFilter({ baseTable: 'all' })
  }

  function updateLocalStorage (data, objToUpdate, type) {
    window.localStorage.setItem(objToUpdate, JSON.stringify(data))
    dispatch({
      type,
      payload: data
    })
  }

  function formatJson (confirmations, campaign, stateAsSelector = true, is6Month = false, changeHeader = false, format = true) {
    if (confirmations.length > 0 && campaign !== '') {
      let formattedJson = confirmations
      if (format) {
        formattedJson = confirmations.map(({ updatedAt, createdAt, __v, ...row }) => {
          const r = {
            ...row,
            date: strToDate(row.date),
            time: strToTime(row.time),
            status: formatRowStatus(row.status, campaign, client._id),
            guardian: formatGuardian(row.guardian)
          }
          if (client._id === clients.JUAN_PABLO_GONZALEZ && campaign === 'marketing') {
            delete r.guardian
            delete r.time
            delete r.name
          }
          if (r.contact) r.contact = formatContact(row.contact)
          return r
        })
      }
      const orderedJson = reorderJsonTable(formattedJson, client._id, user, isHistory, stateAsSelector, is6Month, campaign)
      setJson(orderedJson)
      if (changeHeader) setHeader(formatHeader(orderedJson, client, campaign))
      countResponseStatus(confirmations, campaign, client._id, setFlowStates)
    }
  }

  return (
    <div
      className={`main ${(isHistory.current ? campaignState2 : campaignState) === 'scheduling' ? 'bg-color-2' : ''}`}
    >
      <div className='main-container'>
        <div className='row main-container-row'>
          {(window.outerWidth > 1028 || json.length === 0) &&
            <Menu
              fileName={fileName}
              loadingTable={loadingTable}
              setDateForDB={setDateForDB}
              campaignState={campaignState}
              setCampaignState={setCampaignState}
              campaignState2={campaignState2}
              setCampaignState2={setCampaignState2}
              dateForDB={dateForDB}
              setTableState={setTableState}
              reset={reset}
              formatJson={formatJson}
              setJson={setJson}
              setHeader={setHeader}
              setFileName={setFileName}
              isHistory={isHistory}
              setLoadingTable={setLoadingTable}
              setStates={setStates}
              updateLocalStorage={updateLocalStorage}
              setIsLoading={setIsLoading}
            />}
          {(window.outerWidth > 1028 || json.length > 0) &&
            <div className='col-md-9 information'>
              <div className='row'>
                <div className='information-container'>
                  {window.outerWidth < 1028 &&
                    <button
                      onClick={() => {
                        setCampaignState2('')
                        reset()
                      }}
                      className='back-btn'
                    >
                      <i className='fas fa-arrow-left' />
                    </button>}
                  {isLoading === true
                    ? (
                      <div className='loading-container'>
                        <div className='loading'>
                          <div className='loading-animation'>
                            <MetroSpinner size={70} color='#304044' loading={isLoading} />
                          </div>
                        </div>
                      </div>
                      )
                    : json.length > 0 &&
                      <CampaignTable
                        json={json}
                        header={header}
                        tableState={tableState}
                        flowStates={flowStates}
                        isHistory={isHistory}
                        onWpStateChange={onWpStateChange}
                        campaignState={campaignState}
                        campaignState2={campaignState2}
                        fileName={fileName}
                        savingMessagesStatus={savingMessagesStatus}
                        setSavingMessagesStatus={setSavingMessagesStatus}
                        updateLocalStorage={updateLocalStorage}
                        setTableState={setTableState}
                        setFlowStates={setFlowStates}
                        states={states}
                        filter={filter}
                        resetFilters={resetFilters}
                      />}
                </div>

                <Footer />
              </div>
            </div>}
        </div>
      </div>
    </div>
  )
}

export default Main
