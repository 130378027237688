import DefaultFormat from './default_format.helper'

export default class DermatologicaFormat extends DefaultFormat {
  formatLocationCSV () {
    const { location } = this.jsonObj
    let rawLocation = ''
    const locations = {
      poblado: 'Poblado: Calle 10 # 30 - 310.',
      city_plaza: 'City Plaza: Calle 36D Sur # 27A - 105, Envigado.',
      punto_clave: 'Punto Clave: Calle 27 # 46 - 70. Sector Industriales, locales 131-132.',
      laureles: 'Laureles: Diagonal 74A # 33 AA - 129.',
      centro_comercial_san_nicolas: 'Centro Comercial San Nicolás: Calle 43 # 54 - 139, local 1401, Rionegro.',
      viva_envigado: 'Viva Envigado: Carrera 48 # 32B Sur - 139, local 305, Envigado.',
      rosales: 'Rosales: Calle 71 # 10 - 47.',
      chico: 'Chico: Calle 97 # 23 - 37.'
    }

    if (rawLocation === '') rawLocation = location
    let l = locations.poblado
    if (rawLocation.includes('City Plaza')) l = locations.city_plaza
    if (rawLocation.includes('Punto Clave')) l = locations.punto_clave
    if (rawLocation.includes('Laureles')) l = locations.laureles
    if (rawLocation.includes('Centro Comercial San Nicolás')) l = locations.centro_comercial_san_nicolas
    if (rawLocation.includes('Viva Envigado')) l = locations.viva_envigado
    if (rawLocation.includes('Rosales')) l = locations.rosales
    if (rawLocation.includes('Chico')) l = locations.chico
    return l
  }
}
