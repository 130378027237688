import { Modal } from 'bootstrap'
import parse from 'html-react-parser'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { createNewUser, deleteUser, getUsersByClient, updateUser, updateUserRole } from '../../../actions/user'
import { formatRole, formatStatus } from '../../../helpers/table.helper'
import CreateUserModal from '../../modals/CreateUserModal/CreateUserModal'
import RolePicker from '../../pickers/RolePicker/RolePicker'
import BaseTable from '../BaseTable/BaseTable'
import './UsersTable.css'

function UsersTable () {
  const location = useLocation()

  const user = useSelector((state) => state.user)
  const client = useSelector((state) => state.client)
  const token = useSelector((state) => state.token)

  const header = useRef(['Correo', 'Nombre', 'Rol', 'Estado', 'Sede'])
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [action, setAction] = useState('create')
  const [modalState, setModalState] = useState(null)
  const modal = useRef()
  const calledUsers = useRef(false)

  useEffect(() => {
    setModalState(new Modal(modal.current))
  }, [])

  useEffect(() => {
    let isActive = true
    if (isActive && !calledUsers.current) {
      calledUsers.current = true
      getUsers()
    }
    return function cleanup () {
      isActive = false
    }
  }, [])

  async function getUsers () {
    try {
      const res = await getUsersByClient(user, client._id, token)
      if (res && res?.data && res.data.length > 0) setUsers(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  async function createUser (newUser, setNewUser) {
    setLoading(true)
    try {
      const res = await createNewUser(newUser, user, token)
      modalState.hide()
      if (res.data) {
        setUsers(prevData => [...prevData, res.data])
        setNewUser(prevData => ({ ...prevData, email: '', firstName: '', lastName: '' }))
        toast.success('Usuario agregado con éxito.')
      }
    } catch (error) {
      toast.error(error.response.data.error || error.response.data.message)
    }
    setLoading(false)
  }

  async function updateUserDB (newUser) {
    setLoading(true)
    try {
      const res = await updateUser(newUser, user, token)
      modalState.hide()
      if (res.data) {
        setUsers(prevData => {
          return prevData.map((u) => {
            if (u.email === res.data.email) {
              return res.data
            }
            return u
          })
        })
        toast.success('Usuario actualizado con éxito.')
      }
    } catch (error) {
      toast.error(error.response.data.error || error.response.data.message)
    }
    setLoading(false)
  }

  async function deleteUsr (data) {
    if (
      !window.confirm(
        '¿Estas seguro que quieres eliminar este usuario? Esta acción no se puede deshacer.'
      )
    ) { return }

    const foundUser = users.find((u) => u.email === data.email)
    if (foundUser !== '') {
      try {
        const { data } = await deleteUser(foundUser, user, token)
        if (data) {
          const newUserArray = users.filter((u) => u.email !== data.email)
          setUsers(newUserArray)
          toast.success('Usuario eliminado con éxito.')
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  async function onRoleChange (e, i, u) {
    setUsers(prevData => {
      const newData = [...prevData]
      newData[i].role = e.target.value
      return newData
    })
    try {
      await updateUserRole(u, user, token)
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) toast.error(error.response.data.error || error.response.data.message)
      }
    }
  }

  function formatUsersJson (resUsers) {
    const formattedUsers = []
    // Only admins can edit other users
    if (user.role === 'ADMIN' && !header.current.includes('Opciones')) {
      header.current.push('Opciones')
    }
    resUsers.forEach((u) => {
      const formattedUser = {
        email: u.email,
        name: `${u.firstName} ${u.lastName}`,
        role: formatRole(u.role),
        status: formatStatus(u.status),
        location: u.location && u.location.length > 0 ? u.location : ['Todas']
      }
      if (user.role === 'ADMIN') {
        formattedUser.role = u.role === 'ADMIN'
          ? u.email.includes('bot') ? 'Bot' : 'Administrador'
          : '<select id="role"></select>'
        formattedUser.options = '<button id="location">Sede</button>'
      }
      formattedUsers.push(formattedUser)
    })
    return formattedUsers
  }

  function showUpdateUserModal (data) {
    const foundUser = users.find((u) => u.email === data.email)
    setSelectedUser(foundUser)
    setAction('update')
    modalState.show()
  }

  function formatRowData (rowData, row, i) {
    if (user.role === 'ADMIN' &&
      location.pathname === '/profile' &&
      rowData !== null &&
      rowData !== undefined
    ) {
      if (rowData.includes('<button')) {
        return parse(rowData, {
          replace: ({ attribs }) => {
            return (
              <div className='users-table-options'>
                <button
                  className='btn main-btn dr-btn edit-btn text-nowrap'
                  onClick={() => showUpdateUserModal(row)}
                >
                  <i className='fas fa-pen' />
                </button>
                <button
                  className='btn main-btn dr-btn delete-btn text-nowrap'
                  onClick={() => deleteUsr(row)}
                  disabled={row.email === user.email || row.role === 'Bot'}
                >
                  <i className='fas fa-trash-alt' />
                </button>
              </div>
            )
          }
        })
      } else if (rowData.includes('<select') && users.length > 0) {
        return parse(rowData, {
          replace: ({ attribs }) => {
            if (attribs && attribs?.id === 'role' && users[i]?.role) {
              return (
                <RolePicker
                  value={users[i].role}
                  onChange={async (e) => await onRoleChange(e, i, users[i])}
                />
              )
            }
          }
        })
      } else if (typeof rowData === 'object' && rowData.length > 0) {
        return parse(rowData.join(), {
          replace: ({ attribs }) => {
            return (
              <div className='d-flex flex-column'>
                {
                  rowData.map((u, i) => {
                    return (
                      <p key={i} className='text-nowrap'>
                        {u}
                      </p>
                    )
                  })
                }
              </div>
            )
          }
        })
      }
    }

    return rowData
  }

  return (
    <div className='users'>
      <CreateUserModal
        modal={modal}
        createUser={createUser}
        updateUser={updateUserDB}
        modalState={modalState}
        loading={loading}
        selectUser={selectedUser}
        action={action}
      />

      {window.outerWidth > 1028 && <h1 className='information-title'>Usuarios</h1>}
      <div className='users-container'>
        {user.role === 'ADMIN'
          ? (
            <div className='row d-flex'>
              <div className='col-md-9 title-col-1'>
                <p>
                  Aqui puedes ver a los usuarios que tienen acceso a esta cuenta.
                  Eres el propietario y solo tu puedes agregar, editar y eliminar a los usuarios que se encuentran
                  debajo.
                </p>
              </div>
              <div className='col-md-3 title-col-2'>
                <button
                  className='btn main-btn'
                  onClick={() => {
                    setSelectedUser(null)
                    setAction('create')
                    modalState.show()
                  }}
                >
                  Agregar nuevo usuario
                </button>
              </div>
            </div>
            )
          : (
            <div className='row d-flex'>
              <div className='col-md-12 title-col-1'>
                <p>
                  Aqui puedes ver a los usuarios que tienen acceso a esta cuenta.
                  Solo el propietario de la cuenta puede agregar, editar y eliminar a los usuarios que se encuentran
                  debajo.
                </p>
              </div>
            </div>
            )}

        <BaseTable
          json={formatUsersJson(users)}
          header={header.current}
          formatRowData={formatRowData}
        />
      </div>
    </div>
  )
}

export default UsersTable
