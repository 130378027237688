import clients from './clients'
import { wpStatus, wpStatus6Month } from './wpStatus'

export const flowStates = {
  [clients.PAULINA_ZARRABE]: {
    confirmation: {
      STORED: wpStatus.STORED,
      WP1_SENT: wpStatus.SENT,
      WP1_REPLIED_YES: wpStatus.YES,
      WP1_REPLIED_CALL: wpStatus.CALL,
      WP1_REPLIED_OTHER: wpStatus.OTHER,
      WP1_NO_REPLY: wpStatus.NO_REPLY,
      WP1_REPLIED_NO: wpStatus.SENT,
      WP1_CONV_REPLIED_NO: wpStatus.NO,
      WP1_CONV_REPLIED_CALL: wpStatus.CALL,
      WP1_CONV_NO_REPLY: wpStatus.NO_REPLY,
      MANUAL_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_NO: wpStatus.NO,
      MANUAL_REPLIED_CALL: wpStatus.CALL,
      MANUAL_REPLIED_OTHER: wpStatus.OTHER,
      MANUAL_NO_REPLY: wpStatus.NO_REPLY,
      MANUAL_NOT_ATTENDED: wpStatus.NOT_ATTENDED,
      MANUAL_RESCHEDULED: wpStatus.RESCHEDULED,
      MANUAL_NO_ANSWER: wpStatus.NO_ANSWER,
      MANUAL_PATIENT_WILL_CALL: wpStatus.PATIENT_WILL_CALL
    },
    scheduling: {
      STORED: wpStatus.STORED,
      WP1_SENT: wpStatus.SENT,
      WP1_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_NO: wpStatus.NO,
      MANUAL_REPLIED_CALL: wpStatus.CALL
    },
    '6 month': {
      STORED: wpStatus6Month.STORED,
      WP1_SENT: wpStatus6Month.SENT,
      WP1_REPLIED_CALL: wpStatus6Month.CALL,
      WP1_REPLIED_OTHER: wpStatus6Month.OTHER,
      WP1_NO_REPLY: wpStatus6Month.NO_REPLY,
      WP1_REPLIED_NO: wpStatus6Month.NO,
      MANUAL_REPLIED_YES: wpStatus6Month.YES,
      MANUAL_REPLIED_NO: wpStatus6Month.NO,
      MANUAL_REPLIED_CALL: wpStatus6Month.CALL,
      MANUAL_REPLIED_OTHER: wpStatus6Month.OTHER,
      MANUAL_NO_REPLY: wpStatus6Month.NO_REPLY,
      MANUAL_NO_ANSWER: wpStatus6Month.NO_ANSWER,
      MANUAL_PATIENT_WILL_CALL: wpStatus6Month.PATIENT_WILL_CALL
    }
  },
  [clients.CASA_DE_SONRISAS]: {
    confirmation: {
      STORED: wpStatus.STORED,
      WP1_SENT: wpStatus.SENT,
      WP1_REPLIED_YES: wpStatus.YES,
      WP1_REPLIED_CALL: wpStatus.CALL,
      WP1_REPLIED_OTHER: wpStatus.OTHER,
      WP1_REPLIED_NO: wpStatus.SENT,
      WP1_NO_REPLY: wpStatus.NO_REPLY,
      WP1_CONV_REPLIED_NO: wpStatus.NO,
      WP1_CONV_REPLIED_YES: wpStatus.CALL,
      WP1_CONV_REPLIED_CALL: wpStatus.CALL,
      WP1_CONV_REPLIED_OTHER: wpStatus.OTHER,
      WP1_CONV_NO_REPLY: wpStatus.NO_REPLY,
      MANUAL_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_NO: wpStatus.NO,
      MANUAL_REPLIED_CALL: wpStatus.CALL,
      MANUAL_REPLIED_OTHER: wpStatus.OTHER,
      MANUAL_NO_REPLY: wpStatus.NO_REPLY,
      MANUAL_NOT_ATTENDED: wpStatus.NOT_ATTENDED,
      MANUAL_RESCHEDULED: wpStatus.RESCHEDULED,
      MANUAL_NO_ANSWER: wpStatus.NO_ANSWER,
      MANUAL_PATIENT_WILL_CALL: wpStatus.PATIENT_WILL_CALL
    },
    scheduling: {
      STORED: wpStatus.STORED,
      WP1_SENT: wpStatus.SENT,
      WP1_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_NO: wpStatus.NO,
      MANUAL_REPLIED_CALL: wpStatus.CALL
    },
    '6 month': {
      STORED: wpStatus6Month.STORED,
      WP1_SENT: wpStatus6Month.SENT,
      WP1_REPLIED_CALL: wpStatus6Month.CALL,
      WP1_REPLIED_OTHER: wpStatus6Month.OTHER,
      WP1_NO_REPLY: wpStatus6Month.NO_REPLY,
      WP1_REPLIED_NO: wpStatus6Month.NO,
      MANUAL_REPLIED_YES: wpStatus6Month.YES,
      MANUAL_REPLIED_NO: wpStatus6Month.NO,
      MANUAL_REPLIED_CALL: wpStatus6Month.CALL,
      MANUAL_REPLIED_OTHER: wpStatus6Month.OTHER,
      MANUAL_NO_REPLY: wpStatus6Month.NO_REPLY
    }
  },
  [clients.KI_PLANNER]: {
    confirmation: {
      STORED: wpStatus.STORED,
      WP1_SENT: wpStatus.SENT,
      WP1_REPLIED_YES: wpStatus.YES,
      WP1_REPLIED_CALL: wpStatus.CALL,
      WP1_REPLIED_OTHER: wpStatus.OTHER,
      WP1_NO_REPLY: wpStatus.NO_REPLY,
      WP1_REPLIED_NO: wpStatus.SENT,
      WP1_CONV_REPLIED_NO: wpStatus.NO,
      WP1_CONV_REPLIED_CALL: wpStatus.CALL,
      WP1_CONV_NO_REPLY: wpStatus.NO_REPLY,
      CALL1_SENT: wpStatus.SENT,
      CALL1_REPLIED_YES: wpStatus.YES,
      CALL1_REPLIED_NO: wpStatus.NO,
      CALL1_REPLIED_CALL: wpStatus.CALL,
      CALL1_REPLIED_OTHER: wpStatus.OTHER,
      CALL1_NO_REPLY: wpStatus.NO_REPLY,
      MANUAL_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_NO: wpStatus.NO,
      MANUAL_REPLIED_CALL: wpStatus.CALL,
      MANUAL_REPLIED_OTHER: wpStatus.OTHER,
      MANUAL_NO_REPLY: wpStatus.NO_REPLY,
      MANUAL_NOT_ATTENDED: wpStatus.NOT_ATTENDED,
      MANUAL_RESCHEDULED: wpStatus.RESCHEDULED,
      MANUAL_NO_ANSWER: wpStatus.NO_ANSWER,
      MANUAL_PATIENT_WILL_CALL: wpStatus.PATIENT_WILL_CALL
    },
    scheduling: {
      STORED: wpStatus.STORED,
      WP1_SENT: wpStatus.SENT,
      WP1_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_NO: wpStatus.NO,
      MANUAL_REPLIED_CALL: wpStatus.CALL
    },
    '6 month': {
      STORED: wpStatus6Month.STORED,
      WP1_SENT: wpStatus6Month.SENT,
      WP1_REPLIED_CALL: wpStatus6Month.CALL,
      WP1_REPLIED_OTHER: wpStatus6Month.OTHER,
      WP1_NO_REPLY: wpStatus6Month.NO_REPLY,
      WP1_REPLIED_NO: wpStatus6Month.NO,
      MANUAL_REPLIED_YES: wpStatus6Month.YES,
      MANUAL_REPLIED_NO: wpStatus6Month.NO,
      MANUAL_REPLIED_CALL: wpStatus6Month.CALL,
      MANUAL_REPLIED_OTHER: wpStatus6Month.OTHER,
      MANUAL_NO_REPLY: wpStatus6Month.NO_REPLY
    }
  },
  [clients.JUAN_PABLO_GONZALEZ]: {
    confirmation: {
      STORED: wpStatus.STORED,
      WP1_SENT: wpStatus.SENT,
      WP1_REPLIED_YES: wpStatus.YES,
      WP1_REPLIED_CALL: wpStatus.CALL,
      WP1_REPLIED_OTHER: wpStatus.OTHER,
      WP1_NO_REPLY: wpStatus.SENT,
      WP1_REPLIED_NO: wpStatus.NO,
      WP2_REPLIED_YES: wpStatus.YES,
      WP2_REPLIED_CALL: wpStatus.CALL,
      WP2_REPLIED_OTHER: wpStatus.OTHER,
      WP2_NO_REPLY: wpStatus.NO_REPLY,
      WP2_REPLIED_NO: wpStatus.NO,
      MANUAL_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_NO: wpStatus.NO,
      MANUAL_REPLIED_CALL: wpStatus.CALL,
      MANUAL_REPLIED_OTHER: wpStatus.OTHER,
      MANUAL_NO_REPLY: wpStatus.NO_REPLY,
      MANUAL_NOT_ATTENDED: wpStatus.NOT_ATTENDED,
      MANUAL_RESCHEDULED: wpStatus.RESCHEDULED,
      MANUAL_NO_ANSWER: wpStatus.NO_ANSWER,
      MANUAL_PATIENT_WILL_CALL: wpStatus.PATIENT_WILL_CALL
    },
    scheduling: {
      STORED: wpStatus.STORED,
      WP1_SENT: wpStatus.SENT,
      WP1_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_NO: wpStatus.NO,
      MANUAL_REPLIED_CALL: wpStatus.CALL
    },
    marketing: {
      STORED: wpStatus.STORED,
      WP1_SENT: wpStatus.SENT
    }
  },
  [clients.URO_SERVICE]: {
    confirmation: {
      STORED: wpStatus.STORED,
      WP1_SENT: wpStatus.SENT,
      WP1_REPLIED_YES: wpStatus.YES,
      WP1_REPLIED_CALL: wpStatus.CALL,
      WP1_REPLIED_OTHER: wpStatus.OTHER,
      WP1_NO_REPLY: wpStatus.NO_REPLY,
      WP1_REPLIED_NO: wpStatus.SENT,
      WP1_CONV_REPLIED_NO: wpStatus.NO,
      WP1_CONV_REPLIED_CALL: wpStatus.CALL,
      WP1_CONV_NO_REPLY: wpStatus.NO_REPLY,
      MANUAL_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_NO: wpStatus.NO,
      MANUAL_REPLIED_CALL: wpStatus.CALL,
      MANUAL_REPLIED_OTHER: wpStatus.OTHER,
      MANUAL_NO_REPLY: wpStatus.NO_REPLY,
      MANUAL_NOT_ATTENDED: wpStatus.NOT_ATTENDED,
      MANUAL_RESCHEDULED: wpStatus.RESCHEDULED,
      MANUAL_NO_ANSWER: wpStatus.NO_ANSWER,
      MANUAL_PATIENT_WILL_CALL: wpStatus.PATIENT_WILL_CALL
    },
    scheduling: {
      STORED: wpStatus.STORED,
      WP1_SENT: wpStatus.SENT,
      WP1_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_NO: wpStatus.NO,
      MANUAL_REPLIED_CALL: wpStatus.CALL
    },
    recommendations: {
      STORED: wpStatus.STORED,
      WP1_SENT: wpStatus.SENT,
      WP1_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_NO: wpStatus.NO,
      MANUAL_REPLIED_CALL: wpStatus.CALL
    },
    evaluation: {
      STORED: wpStatus.STORED,
      WP1_SENT: wpStatus.SENT,
      WP1_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_NO: wpStatus.NO,
      MANUAL_REPLIED_CALL: wpStatus.CALL
    }
  },
  [clients.WAKEUP]: {
    confirmation: {
      STORED: wpStatus.STORED,
      WP1_SENT: wpStatus.SENT,
      WP1_REPLIED_YES: wpStatus.YES,
      WP1_REPLIED_CALL: wpStatus.CALL,
      WP1_REPLIED_OTHER: wpStatus.OTHER,
      WP1_NO_REPLY: wpStatus.NO_REPLY,
      WP1_REPLIED_NO: wpStatus.NO,
      WP1_CONV_REPLIED_YES: wpStatus.YES,
      WP1_CONV_REPLIED_NO: wpStatus.NO,
      WP1_CONV_REPLIED_CALL: wpStatus.CALL,
      WP1_CONV_REPLIED_OTHER: wpStatus.OTHER,
      WP1_CONV_NO_REPLY: wpStatus.NO_REPLY,
      MANUAL_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_NO: wpStatus.NO,
      MANUAL_REPLIED_CALL: wpStatus.CALL,
      MANUAL_REPLIED_OTHER: wpStatus.OTHER,
      MANUAL_NO_REPLY: wpStatus.NO_REPLY,
      MANUAL_NOT_ATTENDED: wpStatus.NOT_ATTENDED,
      MANUAL_RESCHEDULED: wpStatus.RESCHEDULED,
      MANUAL_NO_ANSWER: wpStatus.NO_ANSWER,
      MANUAL_PATIENT_WILL_CALL: wpStatus.PATIENT_WILL_CALL
    },
    confirmation_dr: {
      STORED: wpStatus.STORED,
      WP1_SENT: wpStatus.SENT,
      WP1_REPLIED_YES: wpStatus.SENT,
      WP1_REPLIED_CALL: wpStatus.CALL,
      WP1_REPLIED_OTHER: wpStatus.OTHER,
      WP1_NO_REPLY: wpStatus.NO_REPLY,
      WP1_REPLIED_NO: wpStatus.NO,
      WP1_CONV_REPLIED_YES: wpStatus.YES,
      WP1_CONV_REPLIED_NO: wpStatus.NO,
      WP1_CONV_REPLIED_CALL: wpStatus.PAYMENT,
      WP1_CONV_NO_REPLY: wpStatus.NO_REPLY,
      MANUAL_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_NO: wpStatus.NO,
      MANUAL_REPLIED_CALL: wpStatus.CALL,
      MANUAL_REPLIED_OTHER: wpStatus.OTHER,
      MANUAL_NO_REPLY: wpStatus.NO_REPLY,
      MANUAL_NOT_ATTENDED: wpStatus.NOT_ATTENDED
    }
  },
  [clients.DERMATOLOGICA]: {
    confirmation: {
      STORED: wpStatus.STORED,
      WP1_SENT: wpStatus.SENT,
      WP1_REPLIED_YES: wpStatus.YES,
      WP1_REPLIED_CALL: wpStatus.CALL,
      WP1_REPLIED_OTHER: wpStatus.OTHER,
      WP1_REPLIED_NO: wpStatus.NO,
      WP1_NO_REPLY: wpStatus.NO_REPLY,
      WP1_CONV_REPLIED_NO: wpStatus.NO,
      WP1_CONV_REPLIED_YES: wpStatus.CALL,
      WP1_CONV_REPLIED_CALL: wpStatus.CALL,
      WP1_CONV_REPLIED_OTHER: wpStatus.OTHER,
      WP1_CONV_NO_REPLY: wpStatus.NO_REPLY,
      MANUAL_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_NO: wpStatus.NO,
      MANUAL_REPLIED_CALL: wpStatus.CALL,
      MANUAL_REPLIED_OTHER: wpStatus.OTHER,
      MANUAL_NO_REPLY: wpStatus.NO_REPLY,
      MANUAL_NOT_ATTENDED: wpStatus.NOT_ATTENDED,
      MANUAL_RESCHEDULED: wpStatus.RESCHEDULED,
      MANUAL_NO_ANSWER: wpStatus.NO_ANSWER,
      MANUAL_PATIENT_WILL_CALL: wpStatus.PATIENT_WILL_CALL
    },
    scheduling: {
      STORED: wpStatus.STORED,
      WP1_SENT: wpStatus.SENT,
      WP1_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_NO: wpStatus.NO,
      MANUAL_REPLIED_CALL: wpStatus.CALL
    }
  },
  [clients.DEFAULT]: {
    confirmation: {
      STORED: wpStatus.STORED,
      WP1_SENT: wpStatus.SENT,
      WP1_REPLIED_YES: wpStatus.YES,
      WP1_REPLIED_CALL: wpStatus.CALL,
      WP1_REPLIED_OTHER: wpStatus.OTHER,
      WP1_REPLIED_NO: wpStatus.SENT,
      WP1_NO_REPLY: wpStatus.NO_REPLY,
      WP1_CONV_REPLIED_NO: wpStatus.NO,
      WP1_CONV_REPLIED_YES: wpStatus.CALL,
      WP1_CONV_REPLIED_CALL: wpStatus.CALL,
      WP1_CONV_REPLIED_OTHER: wpStatus.OTHER,
      WP1_CONV_NO_REPLY: wpStatus.NO_REPLY,
      CALL1_SENT: wpStatus.SENT,
      CALL1_NO_REPLY: wpStatus.NO_REPLY,
      CALL1_REPLIED_YES: wpStatus.YES,
      CALL1_REPLIED_NO: wpStatus.NO,
      CALL1_REPLIED_CALL: wpStatus.CALL,
      MANUAL_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_NO: wpStatus.NO,
      MANUAL_REPLIED_CALL: wpStatus.CALL,
      MANUAL_REPLIED_OTHER: wpStatus.OTHER,
      MANUAL_NO_REPLY: wpStatus.NO_REPLY,
      MANUAL_NOT_ATTENDED: wpStatus.NOT_ATTENDED,
      MANUAL_RESCHEDULED: wpStatus.RESCHEDULED,
      MANUAL_NO_ANSWER: wpStatus.NO_ANSWER,
      MANUAL_PATIENT_WILL_CALL: wpStatus.PATIENT_WILL_CALL
    },
    scheduling: {
      STORED: wpStatus.STORED,
      WP1_SENT: wpStatus.SENT,
      WP1_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_YES: wpStatus.YES,
      MANUAL_REPLIED_NO: wpStatus.NO,
      MANUAL_REPLIED_CALL: wpStatus.CALL
    },
    '6 month': {
      STORED: wpStatus6Month.STORED,
      WP1_SENT: wpStatus6Month.SENT,
      WP1_REPLIED_CALL: wpStatus6Month.CALL,
      WP1_REPLIED_OTHER: wpStatus6Month.OTHER,
      WP1_NO_REPLY: wpStatus6Month.NO_REPLY,
      WP1_REPLIED_NO: wpStatus6Month.NO,
      MANUAL_REPLIED_YES: wpStatus6Month.YES,
      MANUAL_REPLIED_NO: wpStatus6Month.NO,
      MANUAL_REPLIED_CALL: wpStatus6Month.CALL,
      MANUAL_REPLIED_OTHER: wpStatus6Month.OTHER,
      MANUAL_NO_REPLY: wpStatus6Month.NO_REPLY
    }
  }
}
