import { toast } from 'react-toastify'
import * as XLSX from 'xlsx'

import clients from '../constants/clients'
import { wpStatus, wpStatus6Month } from '../constants/wpStatus'
import {
  formatImport,
  formatObjKeys,
  formattedKey,
  formattedKeyV2,
  numToDate,
  numToTime,
  orderConfirmationsByDate,
  sheetFormat,
  splitSheet
} from './style_string.helper'

export function excelToJson (e, setHeader, setJsonFromExcel) {
  const files = e.target.files
  if (files && files[0]) {
    const file = files[0]
    // eslint-disable-next-line no-undef
    const fileReader = new FileReader()
    const rABS = !!fileReader.readAsBinaryString
    fileReader.onload = (event) => {
      const wb = XLSX.read(event.target.result, { type: rABS ? 'binary' : 'array' })
      /* Get first worksheet */
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      /* Convert array of arrays */
      let data = XLSX.utils.sheet_to_json(ws, {
        header: 1
      })
      data = data.filter(e => e.length)

      const objKeys = data[0]
      // TODO: validate that all the keys are present, if not show error
      const formattedObjKeys = objKeys.map((key) => formattedKey(key))
      let finalJson = updateKeysInObj(data, formattedObjKeys)
      // Make sure there are no undefined values
      finalJson = finalJson.filter((row) => row.number !== undefined)
      const formattedJson =
        finalJson.map(row => ({ ...row, date: numToDate(row.date), time: numToTime(row.time), status: 'Almacenado' }))
      setHeader(objKeys)
      setJsonFromExcel(formattedJson)
    }
    if (rABS) fileReader.readAsBinaryString(file)
    else fileReader.readAsArrayBuffer(file)
  }
}

export function updateKeysInObj (data, keys) {
  const updatedObj = []
  for (let i = 1; i < data.length; i++) {
    const obj = {}
    for (let j = 0; j < keys.length; j++) {
      obj[keys[j]] = data[i][j]
    }
    updatedObj.push(obj)
  }
  return updatedObj
}

export function reorderJsonTable (jsonArray, clientId, user, isHistory, stateAsSelector = true, is6Month = false, campaign) {
  let newJson = jsonArray.map((json) => {
    if (stateAsSelector && user.role !== 'VIEWER' &&
      (
        json.status === wpStatus.CALL ||
        json.status === wpStatus.NO ||
        json.status === wpStatus.YES ||
        json.status === wpStatus.NO_REPLY ||
        json.status === wpStatus.OTHER ||
        json.status === wpStatus6Month.NO ||
        json.status === wpStatus6Month.YES ||
        json.status === wpStatus6Month.NO_ANSWER ||
        json.status === wpStatus6Month.OTHER ||
        json.status === wpStatus.NOT_ATTENDED ||
        json.status === wpStatus.RESCHEDULED ||
        json.status === wpStatus.NO_ANSWER ||
        json.status === wpStatus.PAYMENT ||
        json.status === wpStatus.PATIENT_WILL_CALL
      )
    ) {
      json.status = `<select name="wp-status" id="${json._id}"></select>`
    }
    if (is6Month) {
      // Change statuses for 6 month campaign in history table
      if (json.status === wpStatus.YES) json.status = wpStatus6Month.YES
      if (json.status === wpStatus.NO) json.status = wpStatus6Month.NO
      if (json.status === wpStatus.NO_ANSWER) json.status = wpStatus6Month.NO_ANSWER
    }

    let obj = {
      status: json.status,
      number: json.number,
      name: json.name,
      date: json.date,
      time: json.time,
      doctor: json.doctor
    }
    if (campaign === 'marketing') {
      obj = {
        status: json.status,
        number: json.number,
        firstName: json.firstName,
        lastName: json.lastName,
        date: json.date,
        govtId: json.govtId
      }
    }
    if (Object.keys(json).includes('specialty')) obj.specialty = json.specialty
    if (Object.keys(json).includes('procedure')) obj.procedure = json.procedure
    if (clientId === clients.DERMATOLOGICA) obj.category = json.category ?? 'N/A'
    if (Object.keys(json).includes('guardian')) obj.guardian = json.guardian
    if (Object.keys(json).includes('contact')) obj.contact = json.contact
    if (Object.keys(json).includes('location')) obj.location = json.location
    if (Object.keys(json).includes('lab')) obj.lab = json.lab
    if (Object.keys(json).includes('type')) obj.type = json.type
    if (clientId === clients.CASA_DE_SONRISAS || clientId === clients.URO_SERVICE) obj.userId = json.userId
    if (Object.keys(json).includes('_id')) obj._id = json._id
    return obj
  })
  newJson = orderConfirmationsByDate(newJson)
  return newJson
}

export async function csvToJson (e, setHeader, setJson, client, doctors, user, campaignState, setIsLoading, setStates, setTableState) {
  const files = e.target.files
  const clientEndpoint = formatImport(client)
  if (files && files[0]) {
    const file = files[0]
    // eslint-disable-next-line no-undef
    const fileReader = new FileReader()
    fileReader.onload = async (e) => {
      try {
        if (file.type !== 'text/csv') throw Error('El archivo debe ser un CSV.')
        const text = e.target.result
        const promises = await formatCSVToObj(text, client, clientEndpoint, 'confirmations', doctors, campaignState)
        let json = await Promise.all(promises)
        json = json?.filter(x => x !== null)
          .filter((item, pos, self) => self.indexOf(item) === pos)
        if (json.length === 0) throw Error('Revisa que el formato sea correcto.')
        const orderedJson = reorderJsonTable(json, client._id, user, false, campaignState)
        setStates(['Almacenado'])
        const formattedHeader = Object.keys(orderedJson[0]).map(h => formattedKeyV2(h, client, campaignState))
        setHeader(formattedHeader)
        setJson(orderedJson)
        setTableState({ confirmations: orderedJson })
      } catch (e) {
        console.log(e)
        toast.error('Error al leer el archivo: ' + e.message)
      }
      setIsLoading(false)
    }

    fileReader.readAsText(file)
  }
}

export function jsonToCsv (json, header) {
  const objToArray = json.map((obj) => Object.values(obj))
  const array = [header, ...objToArray]

  return array.map(row =>
    row
      .map(String) // convert every value to String
      .map(v => v.replaceAll('"', '""')) // escape double colons
      .map(v => `"${v}"`) // quote it
      .join(';')
  ).join('\r\n') // rows starting on new lines
}

export async function csvToJsonPatients (e, savePatientsArr, client, user) {
  const files = e.target.files
  const clientEndpoint = formatImport(client)
  if (files && files[0]) {
    const file = files[0]
    // eslint-disable-next-line no-undef
    const fileReader = new FileReader()
    fileReader.onload = async (e) => {
      try {
        if (file.type !== 'text/csv') throw Error('El archivo debe ser un CSV.')
        const text = e.target.result
        const promises = await formatCSVToObj(text, client, clientEndpoint, 'patients')
        let json = await Promise.all(promises)
        json = json?.filter(x => x !== null && x !== undefined)
          .filter((item, pos, self) => self.indexOf(item) === pos)
        if (json.length === 0) throw Error('Revisa que el formato sea correcto.')
        await savePatientsArr(json)
      } catch (e) {
        console.log(e)
        toast.error('Error al leer el archivo: ' + e.message)
      }
    }

    fileReader.readAsText(file)
  }
}

async function formatCSVToObj (
  text,
  client,
  clientEndpoint,
  helperType,
  doctors = [],
  campaignState = ''
) {
  let lines = splitSheet(text, client._id)
  lines = lines.filter(e => e !== '')
  // Remove empty lines
  lines = lines.filter(e => {
    const l = e.replaceAll(';', '').replace(/[\r\n]+/g, '')
    return l !== ''
  })
  const optionalParameter = lines[0]
  let objKeys = sheetFormat(lines, client._id)
  objKeys = formatObjKeys(objKeys, client)
  const promises = []
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i]
    await sleep(20)
    const bits = line.split(';')
    if (!bits.some(e => e !== '')) return null
    const obj = {}
    objKeys.forEach((h, i) => { obj[h] = bits[i] })
    if (Object.values(obj).includes(undefined) && !obj.doctor === undefined) return null
    if (helperType === 'patients') obj.clientId = client._id
    // Call the correct format helper
    const Formatter = require(`./${helperType}/${clientEndpoint}_format.helper.js`).default
    const format = new Formatter(obj, doctors, campaignState, optionalParameter)
    promises.push(await format.formatCSVJson())
  }
  return promises
}

const sleep = (ms = 0) => new Promise(resolve => setTimeout(resolve, ms))
