import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import LoadingBtn from '../../buttons/LoadingBtn/LoadingBtn'
import CreateUserForm from '../../forms/CreateUserForm/CreateUserForm'
import { clientLocation } from '../../../constants/location'
import './CreateUserModal.css'

function CreateUserModal ({ modal, createUser, updateUser, action, modalState, loading, selectUser = null }) {
  const client = useSelector((state) => state.client)
  const initialUser = {
    email: selectUser?.email ?? '',
    firstName: selectUser?.firstName ?? '',
    lastName: selectUser?.lastName ?? '',
    clientId: client._id,
    role: selectUser?.role ?? 'MAINTAINER',
    location: selectUser?.location ?? [],
    status: selectUser?.status ?? 'PENDING',
    _id: selectUser?._id ?? ''
  }
  const [newUser, setNewUser] = useState(initialUser)

  useEffect(() => {
    if (selectUser) setNewUser({ ...selectUser })
    else setNewUser(initialUser)
  }, [selectUser])

  function handleChange (event) {
    const { name, value } = event.target

    setNewUser((prevData) => {
      return {
        ...prevData,
        [name]: value
      }
    })
  }

  return (
    <div
      className='modal fade user-modal'
      ref={modal}
      id='staticBackdrop'
      data-bs-keyboard='false'
      tabIndex='-1'
      aria-labelledby='staticBackdropLabel'
      aria-hidden='true'
      role='dialog'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title' id='staticBackdropLabel'>
              {action === 'create' ? 'Crear usuario' : 'Editar usuario'}
            </h5>
            <button
              type='button'
              className='btn-close'
              onClick={() => modalState.hide()}
            />
          </div>

          <div className='modal-body'>
            <CreateUserForm
              user={newUser}
              setUser={setNewUser}
              handleChange={handleChange}
              options={clientLocation[client._id]}
              action={action}
            />

            <div className='done-container'>
              <div className='center-btn'>
                {!loading
                  ? (
                    <button
                      type='button'
                      className='btn main-btn'
                      onClick={async () => {
                        if (action === 'create') {
                          await createUser(newUser, setNewUser)
                        } else {
                          await updateUser(newUser, setNewUser)
                        }
                      }}
                      disabled={
                        newUser.email === '' ||
                        newUser.firstName === '' ||
                        newUser.lastName === ''
                      }
                    >
                      {action === 'create' ? 'Listo' : 'Actualizar'}
                    </button>
                    )
                  : (<LoadingBtn isLoading={loading} text='Guardando...' />)}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateUserModal
