import InformationPopover from '../../modals/InformationPopover/InformationPopover'
import ResultCard from '../../cards/ResultCard/ResultCard'
import './Results.css'

function Results ({ flowStates, downloadCSV, json, header, table }) {
  function formatFlowStates () {
    if (table.campaign === 'scheduling' || table.campaign === 'marketing') return
    if (table.campaign === '6 month') {
      return (
        <>
          <ResultCard iconClass='fas fa-phone-alt' text={`${flowStates.call} Contactar`} />
          <ResultCard iconClass='fas fa-phone-slash' text={`${flowStates.cancelled} No contactar`} />
          <ResultCard iconClass='fas fa-calendar-check' text={`${flowStates.confirmed} Agendados`} />
          <ResultCard iconClass='fas fa-user-alt-slash' text={`${flowStates.noReply} Ignorado`} />
          {flowStates.other > 0 && <ResultCard iconClass='fas fa-pencil-alt' text={`${flowStates.other} Escritura`} />}
          {flowStates.noAnswer > 0 && <ResultCard iconClass='fas fa-comment-slash' text={`${flowStates.noAnswer} No contestaron`} />}
          {flowStates.patientWillCall > 0 && <ResultCard iconClass='fas fa-phone-alt' text={`${flowStates.patientWillCall} Paciente llamará`} />}
        </>
      )
    }

    return (
      <>
        <ResultCard iconClass='fas fa-check' text={`${flowStates.confirmed} Confirmados`} />
        <ResultCard iconClass='fas fa-times' text={`${flowStates.cancelled} Cancelados`} />
        <ResultCard iconClass='fas fa-phone-alt' text={`${flowStates.call} Contactar`} />
        <ResultCard iconClass='fas fa-user-alt-slash' text={`${flowStates.noReply} Ignorado`} />
        <ResultCard iconClass='fas fa-pencil-alt' text={`${flowStates.other} Escritura`} />
        {flowStates.notAttended > 0 && <ResultCard iconClass='fas fa-calendar-times' text={`${flowStates.notAttended} No asistieron`} />}
        {flowStates.rescheduled > 0 && <ResultCard iconClass='fas fa-calendar-check' text={`${flowStates.rescheduled} Reagendados`} />}
        {flowStates.noAnswer > 0 && <ResultCard iconClass='fas fa-comment-slash' text={`${flowStates.noAnswer} No contestaron`} />}
        {flowStates.payment > 0 && <ResultCard iconClass='fas fa-dollar-sign' text={`${flowStates.payment} Pago`} />}
        {flowStates.patientWillCall > 0 && <ResultCard iconClass='fas fa-phone-alt' text={`${flowStates.patientWillCall} Paciente llamará`} />}
      </>
    )
  }

  return (
    <div className='results'>
      <InformationPopover />
      <div className='row result-row d-flex'>
        <ResultCard iconClass='fas fa-paper-plane' text={`${flowStates.sent} Enviados`} />
        <ResultCard iconClass='fas fa-envelope' text={`${flowStates.delivered} Entregados`} />
        <ResultCard iconClass='fas fa-eye' text={`${flowStates.read} Vistos`} />
        {formatFlowStates()}
      </div>
      <button className='main-btn send-btn' onClick={() => downloadCSV(json, header)}>
        <div className='row main-btn-content'>
          <div className='col-2'><i className='fas fa-download' /></div>
          <div className='col-10'>Descargar</div>
        </div>
      </button>
    </div>
  )
}

export default Results
