import axios from 'axios'
import { setConfig } from '../helpers/set_config.helper'

export async function sendMessages (admin, token, data) {
  const config = await setConfig(admin, token)
  return await axios.post(`${process.env.REACT_APP_CONFIRMATION_API}confirmation`, data, config)
}

export async function getTableByDate (admin, token, date, campaign, clientId) {
  const config = await setConfig(admin, token)
  return await axios.get(`${process.env.REACT_APP_CONFIRMATION_API}confirmation/${date}/${campaign}/${clientId}`, config)
}

export async function updateConfirmationStatus (admin, token, data) {
  const config = await setConfig(admin, token)
  return await axios.put(`${process.env.REACT_APP_CONFIRMATION_API}confirmation-status`, data, config)
}

export async function getDatesWithCampaign (admin, token, campaign, clientId, date) {
  const config = await setConfig(admin, token)
  return await axios.get(`${process.env.REACT_APP_CONFIRMATION_API}confirmation-dates/${campaign}/${clientId}/${date}`, config)
}

export async function getConfirmationsByClientAndDate (admin, token, dates, clientId, location) {
  const config = await setConfig(admin, token)
  return await axios.get(`${process.env.REACT_APP_CONFIRMATION_API}confirmations/${dates}/${clientId}/${location}`, config)
}

export async function getPercentageByClientAndDate (admin, token, data) {
  const config = await setConfig(admin, token)
  return await axios.put(`${process.env.REACT_APP_CONFIRMATION_API}confirmations-percentage`, data, config)
}

// export async function getConfirmationsUserByClientAndDate (admin, token, dates, clientId) {
//   const config = await setConfig(admin, token)
//   return await axios.get(`${process.env.REACT_APP_CONFIRMATION_API}confirmations-user/${dates}/${clientId}`, config)
// }

// export async function getConfirmationsUserStatistics (admin, token, dates, clientId) {
//   const config = await setConfig(admin, token)
//   return await axios.get(`${process.env.REACT_APP_CONFIRMATION_API}confirmations-stats/${dates}/${clientId}`, config)
// }

export async function getFirstConfirmationRange (admin, token, clientId) {
  const config = await setConfig(admin, token)
  return await axios.get(`${process.env.REACT_APP_CONFIRMATION_API}confirmations-stats/${clientId}`, config)
}

// export async function findUsersNotReturned (admin, token, range, clientId, location) {
//   const config = await setConfig(admin, token)
//   return await axios.put(`${process.env.REACT_APP_CONFIRMATION_API}confirmations-stats`, {
//     range,
//     clientId,
//     location
//   }, config)
// }

// export async function findeEscapedPatients (admin, token, dateRange, clientId, location) {
//   const config = await setConfig(admin, token)
//   return await axios.patch(`${process.env.REACT_APP_CONFIRMATION_API}stats/escaped-patients,`, {
//     dateRange,
//     clientId,
//     location
//   }, config)
// }

export async function verifyNumMessagebird (num) {
  const config = {
    headers: {
      Authorization: `AccessKey ${process.env.REACT_APP_MESSAGEBIRD_API_KEY.toString()}`
    }
  }
  return await axios.get(`https://rest.messagebird.com/lookup/${num}`, config)
}
