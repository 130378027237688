import { CircleSpinner } from 'react-spinners-kit'
import './UsageCard.css'

function UsageCard ({
  title,
  value,
  total,
  icon,
  color,
  isLoadingStates,
  isLoadingPercentage,
  isIncreasing,
  percentage = null
}) {
  function getPercentage () {
    return Math.round(parseInt((value / total) * 100))
  }

  return (
    <div className='col-md-3'>
      <div className={`usage-card ${color && color + '-card'}`}>
        {isLoadingStates
          ? (
            <div className='loaderDiv'>
              <CircleSpinner size={20} color='#304044' className='loader' />
            </div>
            )
          : (
            <div className='usage-card-content'>
              <div className='row usage-card-row'>
                <div className='col-7'>
                  <h5 className='dark-txt text-nowrap'>{title}</h5>
                </div>
                {icon &&
                  <div className='col-3'>
                    <p><i className={'fas ' + icon} /></p>
                  </div>}
              </div>
              <div className='row usage-card-row'>
                <div className='col-7'>
                  <h3 className={(color && color !== 'total') ? 'dark-txt' : undefined}>{value}</h3>
                </div>
                {total &&
                  <div className='col-4'>
                    <p className='dark-txt'>{getPercentage() + '%'}</p>
                  </div>}
              </div>

              {!isLoadingPercentage && percentage !== null && (
                <div className='card-footer-stats'>
                  {
                    percentage === 0
                      ? <p className='no-percentage'>-</p>
                      : (
                        <>
                          <p className='trend-icon-stats'><i
                            className={'fas ' + (isIncreasing ? 'fa-arrow-up' : 'fa-arrow-down')}
                                                          />
                          </p>
                          <span className={`trend-text-stats ${isIncreasing ? 'increasing-stats' : 'decreasing-stats'}`}>
                            {Math.round(Math.abs(isNaN(percentage) || !isFinite(percentage) || percentage === undefined ? 0 : percentage))}%
                          </span>
                        </>
                        )
                  }
                </div>)}
            </div>
            )}
      </div>
    </div>
  )
}

export default UsageCard
