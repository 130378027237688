import React, { useEffect, useState } from 'react'
import { Select } from 'antd'

import './LocationPicker.css'

function LocationPicker ({ values, options = [], setUser }) {
  const [isAllSelected, setIsAllSelected] = useState(false)

  useEffect(() => {
    if (values.length > 0 && values.includes('Todas')) {
      setIsAllSelected(true)
    }
  }, [values])

  return (
    <Select
      mode='multiple'
      placeholder='Sede'
      style={{ width: '100%', textAlign: 'left', fontFamily: 'Open Sans' }}
      name='location'
      size='large'
      value={values.length > 0 ? values : []}
      onChange={(value) => {
        if (value.includes('Todas')) {
          setUser((prevData) => ({ ...prevData, location: ['Todas'] }))
        } else {
          setUser((prevData) => ({ ...prevData, location: value.filter(v => v !== 'Todas') }))
          setIsAllSelected(false)
        }
      }}
    >
      {options.map((l, i) => {
        return (
          <Select.Option key={i + l} disabled={isAllSelected && l !== 'Todas'} value={l}>{l}</Select.Option>
        )
      })}
    </Select>
  )
}

export default LocationPicker
