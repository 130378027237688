import clients from './clients'

export const clientLocation = {
  [clients.PAULINA_ZARRABE]: [],
  [clients.CASA_DE_SONRISAS]: [],
  [clients.KI_PLANNER]: [],
  [clients.URO_SERVICE]: [],
  [clients.JUAN_PABLO_GONZALEZ]: [],
  [clients.WAKEUP]: [
    'Todas',
    'Sede Poblado',
    'Sede Laureles',
    'Sede Barranquilla'
  ],
  [clients.DERMATOLOGICA]: [
    'Todas',
    'Sede Poblado',
    'Sede Chico',
    'Sede Rosales'
  ],
  [clients.DEFAULT]: []
}
